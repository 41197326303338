import React from "react"
import { Container } from "react-bootstrap"
import Header from "./header"
import Footer from "./footer"
import ChainsimFull from "./chainsim-full"

const DrillLayout = ({ pageContext: { drill, location } }) => {
  // console.log(location)

  return (
    <>
      <Container fluid className="px-0 main">
        <Header location={location} />
        <ChainsimFull json={drill} autoPlay={true} />
      </Container>
      <Footer />
    </>
  )
}

export default DrillLayout
